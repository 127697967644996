export const useSizeCountry = () => {
  const selectedCountryCookie = useCookie('size-selector-selected-country')
  const { country } = useRouteHelper()
  const currentCountry = country.toUpperCase()

  const sizeCountries = ['IT', 'US', 'UK', 'FRA', 'EU', 'JP']
  const defaultSizeCountry = 'IT'

  const getInitialCountry = (sizeCountries: string[], country: string) =>
    sizeCountries.includes(country) ? country : defaultSizeCountry

  const selectedCountry = ref(
    getInitialCountry(
      sizeCountries,
      selectedCountryCookie.value ?? currentCountry
    )
  )

  watch(selectedCountry, selectedCountry => {
    selectedCountryCookie.value = selectedCountry
  })

  return {
    selectedCountry,
    sizeCountries,
  }
}
